import React from "react"
import Container from "../../components/Container"
import Navbar from "../../components/Navbar"

import Aneta from "../../images/team/aneta-page.png"

import Tomasz from "../../images/team/tomasz.webp"
import Agnieszka from "../../images/team/agnieszka.webp"

import { TbBrandLinkedin } from "react-icons/tb"
import { BsArrowLeftShort, BsTelephone } from "react-icons/bs"
import { FiMail } from "react-icons/fi"
import { Link } from "gatsby"

import { useTranslation } from "../../../i18n"

const aneta = () => {
  const { t } = useTranslation("aneta")

  return (
    <div>
      <Navbar />
      <Container>
        <Link
          to="/"
          className="py-20 text-gray-400 flex flex-row font-content items-center"
        >
          <BsArrowLeftShort size={25} className="mr-2" /> {t("backHome")}
        </Link>
        <div className="flex flex-col lg:flex-row justify-left items-center text-[#262A33]">
          <img className="rounded-md shadow-lg" src={Aneta} alt="Aneta" />
          <div className="lg:ml-32">
            <h1 className="text-4xl">{t("title")}</h1>
            <h2 className="text-5xl">Nowak-Piechota</h2>
            <p className="text-gray-500 font-content mt-4">{t("role")}</p>
            <div className="mt-6 flex flex-row items-center gap-4 text-gray-400">
              <Link
                target="_blank"
                to="https://www.linkedin.com/in/anetanowakpiechota/"
              >
                <TbBrandLinkedin size={40} />
              </Link>
              <a href="tel:+48223552952">
                <BsTelephone size={28} />
              </a>
              <a href="mailto:anowakpiechota@taxharmony.pl">
                <FiMail size={32} />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <Container>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("education")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("educationDesc1")}
            <br />
            <br />
            {t("educationDesc2")}
          </p>
          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl"> {t("quali")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("roleDesc1")} <br />
            {t("roleDesc2")} <br />
            {t("roleDesc3")} <br />
            Certificate of Advance English
          </p>
          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("specialization")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("works")}
          </p>
          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("careerCourse")}</h1>
          <div className="my-10 w-10/12 mx-auto flex flex-row gap-[2rem]">
            <div className="flex flex-col items-center w-10">
              <div className="w-5 h-5 rounded-full bg-golden"></div>
              <hr className="-mt-1 w-[2px] h-16 bg-golden border-0" />
              <div className="w-5 h-5 rounded-full bg-golden"></div>
              <hr className="-mt-1 w-[2px] h-16 bg-golden border-0" />
              <div className="w-5 h-5 rounded-full bg-golden"></div>
              <hr className="-mt-1 w-[2px] h-16 bg-golden border-0" />
              <div className="w-5 h-5 rounded-full bg-golden"></div>
              <hr className="-mt-1 w-[2px] h-16 bg-golden border-0" />
              <div className="w-5 h-5 rounded-full bg-golden"></div>
            </div>
            <div className="flex flex-col justify-between">
              <p className="text-golden font-content font-bold">
                {t("timeFrame1")} <br />
                <span className="text-gray-500 font-normal">
                  {t("company1")}
                </span>
              </p>
              <p className="text-golden font-content font-bold">
                {t("timeFrame2")} <br />
                <span className="text-gray-500 font-normal">
                  {t("company2")}
                </span>
              </p>
              <p className="text-golden font-content font-bold">
                {t("timeFrame3")}
                <br />
                <span className="text-gray-500 font-normal">
                  {t("company3")}
                </span>
              </p>
              <p className="text-golden font-content font-bold">
                2017-2021
                <br />
                <span className="text-gray-500 font-normal">
                  {t("company4")}
                </span>
              </p>
              <p className="text-golden font-content font-bold">
                2009-2017
                <br />
                <span className="text-gray-500 font-normal">
                  {t("company5")}
                </span>
              </p>
            </div>
          </div>

          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("projects")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("project1")}
            <br />
            <br />
            {t("project2")} <br />
            <br />
            {t("project3")} <br />
            <br />
            {t("project4")} <br />
            <br />
            {t("project5")}
            <br />
            <br />
            {t("project6")}
            <br />
            <br />
            {t("project7")}
          </p>
          <hr />
        </div>

        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("awards")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("award1")} <br />
            <br />
            {t("award2")} <br />
            <br />
            {t("award3")} <br />
            <br />
            {t("award4")} <br />
            <br />
            {t("award5")} <br />
            <br />
            <br />
          </p>
          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("other")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("other1")} <br />
            <br />
            {t("other2")}
            <br />
            <br />
            {t("other3")} <br />
            <br />
            {t("other4")} <br />
            <br />
            {t("other5")}
          </p>
          <hr />
        </div>
        <div className="mt-16">
          <h1 className="text-[#262A33] text-5xl">{t("lang")}</h1>
          <p className="text-gray-500 w-10/12 mx-auto font-content my-10">
            {t("lang1")}
          </p>
          <hr />
        </div>
      </Container>
      <section className="flex flex-row bg-gradient-to-t from-[#101627] to-[#292C35] shadow-xl">
        {/* <hr className="w-[50vw] bg-gradient-to-r from-golden to-[#FBD87E] h-1 mt-32 border-0" /> */}

        <Container>
          <div className="flex flex-col lg:flex-row py-32 justify-between">
            <div className="flex items-center flex-col lg:flex-row gap-6">
              <Link to="/team/tomasz">
                <img
                  className="w-48 opacity-75 grayscale rounded-md"
                  src={Tomasz}
                  alt="Aneta"
                />
              </Link>
              <Link to="/team/agnieszka">
                <img
                  className="w-48 opacity-75 grayscale rounded-md"
                  src={Agnieszka}
                  alt="Agnieszka"
                />
              </Link>
            </div>
            <div className="flex flex-col mt-12 lg:mt-0 justify-center items-center lg:items-end text-left">
              <div>
                <h1 className="text-3xl">{t("meet")}</h1>
                <h2 className="ml-8 text-4xl">Tax Harmony</h2>
                <p className="font-content mt-2">
                {t("readAbout")}
                </p>
                <Link
                  to="/"
                  className="py-20 text-white -mb-[12rem] flex flex-row font-content items-center"
                >
                  <BsArrowLeftShort size={25} className="mr-2" />    {t("backHome")}
                </Link>
              </div>
            </div>
          </div>
        </Container>
        <hr className="absolute w-0 lg:w-[50vw] right-0 bg-gradient-to-r from-golden to-[#FBD87E] h-1 mt-[10rem] border-0" />
        <hr className="absolute w-0 lg:w-[50vw] mt-[24rem] right-0 bg-gradient-to-r from-golden to-[#FBD87E] h-1 border-0" />
      </section>
      <footer className="w-full h-32 flex justify-center items-center text-gray-500">
        Copyright © 2022 taxharmony.pl
      </footer>
    </div>
  )
}

export default aneta
